<template>
  <div class="variant-selector">
    <ul>
      <li
        v-for="product in products"
        :key="product.id"
        :class="[
          selectedProductId === product.id ? 'selected' : '',
          'variant-selector__item',
        ]"
        @click="$emit('select-variant', product.id)"
      >
        <span>
          {{ product.tagName }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    selectedProductId: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
@use "~@lib/styles/helpers" as *;
.variant-selector {
  padding: var(--spacer-xs) 0;
  display: flex;
  &__item {
    cursor: pointer;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      display: inline-block;
      padding: 0.25rem 0.5rem;
      background: white;
      border: 1px solid var(--c-primary);
      color: var(--c-primary);
      border-radius: 0px;
      margin: 0.25rem 0.5rem 0.25rem 0;
      font-size: 0.875rem;
      border-radius: 0.25rem;

      &.selected {
        color: white;
        background: var(--c-primary);

        .sf-price__value {
          color: white;
        }
      }

      .sf-price__value {
        color: var(--c-primary);
        line-height: 1.2rem;
        font-size: 0.875rem;
      }
    }
  }

  @include for-desktop {
    padding: var(--spacer-sm) 0;
  }
}
</style>
