<template>
  <SfCarousel
    class="product-carousel"
    :settings="{
      animationDuration: 600,
    }"
  >
    <SfCarouselItem v-for="(product, i) in products" :key="i">
      <ProductGridItem
        :product="product"
        :is-cart-enabled="false"
        :show-stock-errors="false"
        link-tag="router-link"
        :link="'/p/' + product.variantId"
        class="products__grid-item"
      />
    </SfCarouselItem>
  </SfCarousel>
</template>
<script>
import { ProductGridItem, SfCarousel } from "@lib/components";
export default {
  name: "ProductCarousel",
  components: {
    SfCarousel,
    ProductGridItem,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.product-grid-item {
  max-width: 168px;
}
</style>
