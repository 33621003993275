<template>
  <div
    class="product-details product"
    itemprop="offers"
    itemscope
    itemtype="http://schema.org/Offer"
  >
    <!-- <meta
      itemprop="priceCurrency"
      :content="currencyCode"
    /> -->
    <meta
      itemprop="price"
      :content="parseFloat(product.finalPrice).toFixed(2)"
    />
    <meta itemprop="availability" :content="availability" />
    <meta v-if="product.url_path" itemprop="url" :content="product.url_path" />
    <meta v-if="product.image" itemprop="image" :content="product.image" />

    <ProductGallery :gallery="gallery" />
    <div class="product__info">
      <div class="product-info__short">
        <div class="product__header">
          <SfHeading
            :title="product.title"
            :level="3"
            class="sf-heading--no-underline sf-heading--left"
          />
          <p v-if="product.variantName" class="product__variant">
            {{ product.variantName }}
          </p>
        </div>
        <div class="product__description" v-if="product.description">
          <p class="product__variant">{{ product.description }}</p>
        </div>
        <VariantSelector
          v-if="variantTags.length"
          :products="variantTags"
          :selected-product-id="product.variantId"
          @select-variant="goToProduct($event)"
        />
        <div class="product__price-and-rating">
          <SfPrice
            v-if="product.basePrice"
            class="product-details__price"
            :regular="product.basePrice | price"
            :special="product.discountedPrice | price"
          />
        </div>
      </div>
      <div>
        <div
          v-if="isCartEnabled && !isOutOfStock"
          class="product-details__add-to-cart-wrapper"
        >
          <SfAddToCart
            :qty="product.qty"
            :product="product"
            :max="showStockErrors ? product.stock : 10000"
            class="product-details__add-to-cart"
          />
        </div>
        <div
          class="product-details__add-to-cart"
          v-if="isCartEnabled && isOutOfStock"
        >
          <div class="product-details__error">Out of stock</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import get from "lodash-es/get";
import { mapGetters, mapActions } from "vuex";
import {
  SfHeading,
  SfIcon,
  SfButton,
  SfPrice,
  SfAddToCart,
} from "@lib/components";
import ProductGallery from "@theme/components/molecules/product-gallery";

import VariantSelector from "@theme/components/molecules/variant-selector";

export default {
  components: {
    SfHeading,
    SfPrice,
    SfAddToCart,
    ProductGallery,
    VariantSelector,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    variants: {
      type: Array,
      default: () => [],
    },
    productGallery: {
      type: Array,
      default: () => [],
    },
    productConfiguration: {
      type: Object,
      default: () => ({}),
    },
    productCustomOptions: {
      type: Object,
      default: () => ({}),
    },
    productAttributes: {
      type: Array,
      default: () => [],
    },
    productStock: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      store: "store",
    }),
    showStockErrors() {
      return !this.store.orderConfig.allowOrderOnNoStock;
    },
    isCartEnabled() {
      return (
        this.store.orderConfig.orderEnabled ||
        this.store.orderConfig.enquiryEnabled
      );
    },
    isOutOfStock() {
      return this.showStockErrors && this.product.stock <= 0;
    },
    offlineImage() {
      const width = config.products.thumbnails.width;
      const height = config.products.thumbnails.height;
      return {
        mobile: {
          url: this.getThumbnail(this.product.image, width, height),
          alt: this.product.name,
        },
        desktop: {
          url: this.getThumbnail(this.product.image, width, height),
          alt: this.product.name,
        },
      };
    },
    gallery() {
      return this.product.images.map((image) => ({
        id: image.id,
        mobile: {
          url: image.thumbnail || image.url,
        },
        desktop: {
          url: image.url,
        },
        alt: this.product.title,
      }));
    },
    availability() {
      return this.product.stock > 0 ? "InStock" : "OutOfStock";
    },
    sizeOption() {
      return get(this.productConfiguration, "size", false);
    },
    variantTags() {
      if (this.variants.length < 2) {
        return [];
      }
      return this.variants.map((x) => ({
        id: x.variantId,
        tagName: x.variantName || this.$options.filters.price(x.finalPrice),
      }));
    },
  },
  methods: {
    ...mapActions("ui", {
      openModal: "openModal",
    }),
    goToProduct(variantId) {
      this.$router.replace({ name: "product", params: { sku: variantId } });
    },
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/helpers/breakpoints" as *;

.product-details {
  @include for-desktop {
    display: flex;
  }
  .product__info {
    margin: var(--spacer-sm) auto var(--spacer-xs);
    @include for-desktop {
      flex: 1;
      margin: 0 0 0 4rem;
    }

    .sf-heading {
      --heading-title-font-size: var(--font-lg);
    }

    .product-details__price {
      --price-font-size: var(--font-base);
    }
  }
  &__add-to-cart {
    display: flex;
    justify-content: flex-end;
    @include for-desktop {
      margin-top: var(--spacer-2xl);
      justify-content: flex-start;
    }
  }
  .product__guide,
  .product__compare,
  .product__save {
    display: block;
    margin: var(--spacer-xl) 0 var(--spacer-base) auto;
  }

  .product__description {
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -o-pre-wrap; /* newer Opera */
    white-space: pre-wrap; /* Chrome; W3C standard */
    word-wrap: break-word;
  }

  &__error {
    display: block;
    margin-bottom: var(--spacer-xs);
    color: red;
    display: inline-block;
    font-size: var(--font-sm);
  }

  .section {
    border-bottom: 1px solid #f1f2f3;
    padding-bottom: 10px;
    @include for-desktop {
      border: 0;
      padding-bottom: 0;
    }
  }
}
</style>
