<template>
  <div id="product" class="container" itemscope itemtype="http://schema.org/Product" v-if="product">
    <SfBreadcrumbs class="breadcrumbs desktop-only" :breadcrumbs="breadcrumbs">
      <template #link="{ breadcrumb }">
        <router-link :to="breadcrumb.route.link" class="sf-breadcrumbs__breadcrumb">
          {{ breadcrumb.text }}
        </router-link>
      </template>
    </SfBreadcrumbs>
    <ProductDetails :product="product" :variants="variants" />
    <div class="product__bottom">
      <SfSection :title-heading="$t('Other products in the category')"
        v-if="otherCategoryProducts && otherCategoryProducts.length">
        <RelatedProducts :current-product="product" />
      </SfSection>
    </div>
    <div class="powered-by" v-if="!store.hidePlatformBranding">
      <div style="display: inline-block; vertical-align: middle">
        Powered by
      </div>
      <a :href="getPoweredByLink()" style="
          display: inline-block;
          vertical-align: middle;
          height: 18px;
          width: 85px;
          margin-left: 5px;
        ">
        <img alt="Zobaze" src="https://pos-staging-0.web.app/assets/img/logo/zobaze%20logo.png"
          style="height: 100%; width: 100%" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import RelatedProducts from "@theme/components/molecules/related-products";
import ProductDetails from "@theme/components/organisms/product-details";
import { SfSection, SfBreadcrumbs } from "@lib/components";

export default {
  name: "Product",
  components: {
    SfBreadcrumbs,
    RelatedProducts,
    SfSection,
    ProductDetails,
  },
  data() {
    const variantId = this.$route.params.sku;
    return {
      variantId,
      stock: {
        isLoading: false,
        max: 0,
        manageQuantity: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      productsFetchWaiting: "catalog/fetchWaiting",
      productList: "catalog/list",
      store: "store"
    }),
    product() {
      if (this.productList) {
        return this.productList.find(
          (x) => x.variantId === this.$route.params.sku
        );
      }
      return null;
    },
    variants() {
      if (!this.productList.length || !this.product) {
        return [];
      }
      return this.productList
        .filter((x) => x.itemId === this.product.itemId)
        .sort((p1, p2) => {
          if (p1.hasOwnProperty("position") && p2.hasOwnProperty("position")) {
            return p1.position > p2.position ? 1 : -1;
          } else {
            if (p1.variantName && p2.variantName) {
              return p1.variantName > p2.variantName ? 1 : -1;
            } else if (p1.variantName || p2.variantName) {
              return p1.variantName ? 1 : -1;
            } else return p1.price > p2.price ? 1 : -1;
          }
        });
    },
    breadcrumbs() {
      return [
        { text: "home", route: { link: { name: "home" } } },
        { text: "catalog", route: { link: { name: "catalog" } } },
        {
          text: this.product.category,
          route: {
            link: {
              name: "catalog",
              params: { categoryId: this.product.categoryId },
            },
          },
        },
        { text: this.product.title, route: { link: { name: "product" } } },
      ];
      //   return this.getBreadcrumbsRoutes
      //     .map((route) => ({
      //       text: htmlDecode(route.name),
      //       route: {
      //         link: route.route_link,
      //       },
      //     }))
      //     .concat({
      //       text: htmlDecode(this.getBreadcrumbsCurrent),
      //     });
    },
    otherCategoryProducts() {
      return this.productList.filter(
        (x) =>
          x.categoryId == this.product.categoryId &&
          x.itemId != this.product.itemId
      );
    },
  },
  methods: {
    getPoweredByLink() {
      let link =
        "https://pos.zobaze.com/?utm_source=store-front&utm_medium=store-front&utm_campaign=store-front";
      if (window.screen && window.screen.width <= 720) {
        link =
          Math.random() > 0.5
            ? link
            : "https://play.google.com/store/apps/details?id=com.zobaze.pos&referrer=utm_source%3Dstore-front%26utm_medium%3Dstore-front%26utm_term%3Dstore-front%26utm_content%3Dstore-front%26utm_campaign%3Dstore-front";
      }
      return link;
    },
  },
};
</script>

<style lang="scss">
@use "~@lib/styles/helpers/breakpoints" as *;

#product {
  box-sizing: border-box;
  padding: var(--spacer-sm);

  @include for-desktop {
    max-width: 1272px;
    margin: 0 auto;
  }

  .sf-image--has-size img {
    border-radius: 0.375rem;
  }

  .product__bottom {
    box-sizing: border-box;
    padding-bottom: 6rem;

    @include for-desktop {
      padding: 0 var(--spacer-sm);
    }
  }

  .breadcrumbs {
    padding: var(--spacer-base) var(--spacer-base) var(--spacer-base) var(--spacer-sm);
  }

  .banner {
    margin: var(--spacer-xl) 0;

    @include for-desktop {
      margin: var(--spacer-2xl) 0;
    }
  }

  .powered-by {
    text-align: center;
    opacity: 0.65;
    font-size: 14px;
    padding: 1.75rem 0 6rem;
  }
}
</style>
