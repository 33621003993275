<template>
  <product-carousel
    v-if="otherCategoryProducts.length"
    :products="otherCategoryProducts"
  />
</template>

<script>
import ProductCarousel from "@theme/components/molecules/product-carousel";
import { mapGetters } from "vuex";

export default {
  name: "RelatedProducts",
  components: {
    ProductCarousel,
  },
  props: {
    currentProduct: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      productList: "catalog/list",
    }),
    otherCategoryProducts() {
      return this.productList.filter(
        (x) =>
          x.categoryId == this.currentProduct.categoryId &&
          x.itemId != this.currentProduct.itemId
      );
    },
  },
};
</script>

<style lang="scss">
.sf-section__content {
  margin-top: var(--spacer-base);
}
</style>
